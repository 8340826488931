<template>
  <div class="card q-pa-sm shadow border-0 bg-white">
    <div class="text-subtitle1 text-center border-bottom q-pb-xs">
      {{ data.name }} <span>({{ data.id }})</span>
    </div>

    <div class="row q-py-sm fit">
      <img
        class="q-my-auto"
        style="height: 80px; width: 80px; object-fit: contain;"
        :src="data.image || fallbackImage"
        :alt="data.name"
        @error="onImageLoadFailure"
      >

      <div class="col q-pl-xs">
        <div class="row items-center">
          <div class="col-4 text-caption">
            {{ $t('Sku') }}
          </div>

          <div class="col-8 text-subtitle1">
            {{ data.sku }}
          </div>
        </div>

        <div class="row items-center">
          <div class="col-4 text-caption">
            {{ $t('Order') }}
          </div>

          <div class="col-8 text-subtitle1">
            {{ data.order || '--' }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <q-btn
        color="light-blue-9"
        size="sm"
        class="full-width"
        :label="barcodeTitle"
        :disable="(data.barcodes || []).length <= 1"
        @click="handleBarcodes"
      />

      <q-slide-transition>
        <div
          v-if="isOpenBarcodes"
          class="q-py-xs text-caption text-center"
        >
          {{ (data.barcodes || []).join(', ') }}
        </div>
      </q-slide-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PickingOffer',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpenBarcodes: false,
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  computed: {
    barcodeTitle () {
      if ((this.data.barcodes || []).length <= 0) {
        return this.$t('No barcodes')
      }

      return this.$t('Last barcode') + ': ' + this.data.barcodes[this.data.barcodes.length - 1]
    }
  },
  methods: {
    handleBarcodes () {
      this.isOpenBarcodes = !this.isOpenBarcodes
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    }
  }
}
</script>
